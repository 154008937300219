import { AxiosError } from 'axios';
import i18n from 'entities/localization/i18n';
import notificationsStore from 'entities/notification/notificationsStore';

export const apiErrorNotifier = (error: unknown) => {
  if (error instanceof AxiosError) {
    if (!error.response) {
      return Promise.reject({ ...error });
    }

    const notifyStatus = [423, 403];

    const status = error.response.status as keyof typeof i18n.strings.Errors.API.status;
    const text: string | undefined = i18n.strings.Errors.API.status[status];
    if (notifyStatus.includes(status) && text) {
      notificationsStore.notify('error', text);
    }
  }

  return Promise.reject(error);
};
