import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import auth from 'shared/api/auth';
import { apiErrorNotifier } from 'shared/lib/error-handler';
import { getHost } from 'shared/lib/url';

function parseResponseData<T>(data: string): T {
  return JSON.parse(data) as T;
}

export const axiosInstance = axios.create({
  baseURL: `${getHost()}/api`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = await auth.getToken();
  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => apiErrorNotifier(error)
);

async function processRequest<T>(
  action: (api: AxiosInstance) => Promise<AxiosResponse<T>>,
  config?: AxiosRequestConfig
): Promise<T> {
  const response = await action(axiosInstance);
  const raw = !config?.responseType || config.responseType === 'json' || config.responseType === 'blob';
  return raw ? response.data : parseResponseData<T>(response.data as string);
}

async function get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
  return await processRequest<T>((api) => api.get(url, config), config);
}

async function post<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> {
  return await processRequest((api) => api.post(url, data, config), config);
}

async function put<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> {
  return await processRequest((api) => api.put(url, data, config), config);
}

async function del<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
  return await processRequest((api) => api.delete(url, config), config);
}

async function patch<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> {
  return await processRequest((api) => api.patch(url, data, config), config);
}

export default {
  get,
  post,
  put,
  del,
  patch,
};
